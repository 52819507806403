import React, { useState } from "react";
import { Grid, Card, CardContent, Chip } from "@material-ui/core";
import ButtonGeneral from "../../../../../components/ait-reusable/Button/ButtonGeneral";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import { getTipoPublicacion } from "../utils";
import meliLogo from "../../../../../images/meli.png";
import ModalDesvinculacion from "./ModalDesvinculacion";
import ModalDesvinculacionExitosa from "./ModalDesvinculacionExitosa";

export default function ListPublicaciones({
  publicaciones,
  history,
  articuloVinculacion,
  getPublicaciones,
}) {
  const [modalDesvincular, setModalDesvincular] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isDesvinculada, setIsDesvinculada] = useState(false);

  return (
    <Grid container spacing={1}>
      {publicaciones.length > 0 &&
        publicaciones
          .sort((a, b) => a.index - b.index)
          .map((publicacion, index) => {
            return (
              <Grid item xs={12} key={index}>
                <Card>
                  <CardContent
                    style={{
                      padding: 5,
                      maxHeight: "7vh",
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      sx={{
                        justifyContent: "center",
                        alignItems: "left",
                      }}
                    >
                      <Grid item xs={1}>
                        <img
                          alt="Imagen"
                          src={publicacion.imagen}
                          style={{
                            maxWidth: "5vh",
                            maxHeight: "5vh",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Grid container>
                          <Grid item xs={12}>
                            <span style={{ fontSize: 14, color: "#2196f3" }}>
                              {publicacion.id}
                            </span>
                            <img
                              width={35}
                              src={meliLogo}
                              alt="logoML"
                              style={{ cursor: "pointer" }}
                              onClick={() => window.open(publicacion.link)}
                              title={"Ver publicación en Mercado Libre"}
                            />
                            <span
                              className="textMELI"
                              style={{ fontWeight: "bold", fontSize: 16 }}
                            >
                              {publicacion.titulo}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12}>
                            <span style={{ fontSize: 22 }}>
                              {publicacion.precio}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <Grid
                          container
                          spacing={1}
                          direction="column"
                          sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={12}>
                            <Chip
                              label={getTipoPublicacion(
                                publicacion.tipo,
                              ).name.toUpperCase()}
                              size="small"
                              style={{
                                marginRight: 5,
                                color: "white",
                                backgroundColor: getTipoPublicacion(
                                  publicacion.tipo,
                                ).color,
                              }}
                            />
                            {publicacion.variants.length ? (
                              <Chip
                                label="Tiene variantes"
                                color="primary"
                                size="small"
                                variant="outlined"
                                style={{
                                  color: "white",
                                  backgroundColor: "black",
                                }}
                              />
                            ) : null}

                            {publicacion.isCombo ? (
                              <Chip
                                label="COMBO"
                                variant="outlined"
                                color="primary"
                                size="small"
                              />
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container>
                          <Grid item xs={12}>
                            {publicacion.fechaModificacion && (
                              <span style={{ fontSize: 12 }}>
                                {`Última actualización: ${publicacion.fechaModificacion}`}
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {publicacion.articulos ? (
                              <span
                                className="textMELI"
                                style={{ fontSize: 12 }}
                              >
                                {publicacion &&
                                  publicacion.articulos &&
                                  publicacion.articulos.length > 0 &&
                                  publicacion.articulos[0].nombre}
                              </span>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <Grid
                          container
                          direction="row"
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid xs={6}>
                            <ButtonGeneral
                              spacing={2}
                              padding={5}
                              style={{ fontSize: 10 }}
                              size="small"
                              disabled={
                                articuloVinculacion && publicacion.vinculada
                                  ? true
                                  : false
                              }
                              click={() =>
                                history.push(
                                  `/vinculacion-publicacion/mercadolibre/${publicacion.id}`,
                                )
                              }
                              message={`${
                                publicacion.vinculada ? "EDITAR" : "VINCULAR"
                              }`}
                            />
                          </Grid>
                          <Grid xs={6}>
                            {publicacion.vinculada && (
                              <ButtonCancelar
                                spacing={2}
                                padding={5}
                                style={{ fontSize: 10 }}
                                size="small"
                                click={() => {
                                  setModalDesvincular(true);
                                  setSelected(publicacion);
                                }}
                                message={"Desvincular"}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      {modalDesvincular && selected && (
        <ModalDesvinculacion
          modalDesvincular={modalDesvincular}
          setModalDesvincular={setModalDesvincular}
          selected={selected}
          setIsDesvinculada={setIsDesvinculada}
          setSelected={setSelected}
          meliLogo={meliLogo}
        />
      )}

      {isDesvinculada && (
        <ModalDesvinculacionExitosa
          isDesvinculada={isDesvinculada}
          setIsDesvinculada={setIsDesvinculada}
          setSelected={setSelected}
          selected={selected}
          getPublicaciones={getPublicaciones}
          meliLogo={meliLogo}
        />
      )}
    </Grid>
  );
}
