import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export function CalculateStockCombo(articulosSelected) {
  let stock = 0;
  let unidades = [];
  if (articulosSelected.length === 0) {
    return stock;
  } else {
    articulosSelected.forEach((articulo) => {
      // Verificar si el atributo repuesto_sucursal no es null
      if (articulo.repuesto_sucursal !== null) {
        const cantidad = articulo.cantidad;
        const unidades_disponibles = articulo.repuesto_sucursal.stockDisponible;
        unidades.push(Math.floor(unidades_disponibles / cantidad));
      }
    });
    // Calcular el mínimo solo si hay unidades disponibles
    if (unidades.length > 0) {
      const min = Math.min(...unidades);
      stock = min > 0 ? min : 0;
    }
    return stock;
  }
}

export default function FormStock({
  errorStock,
  useArtVinculacion,
  data,
  handleChange,
  publicacion,
  addToSucursal,
  type,
}) {
  const { articulosSelected } = useArtVinculacion;
  const host = window.location.host.split(".")[0];
  const isEdit = publicacion && publicacion.vinculada;
  return (
    <Card>
      <CardHeader
        style={{
          backgroundColor: "#E9E4F0",
          paddingTop: 8,
          paddingBottom: 8,
        }}
        subheader="Stock"
      />
      <CardContent style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Grid container spacing={2}>
          {(!errorStock || (errorStock && isEdit)) && (
            <>
              <Grid item xs={5} sm={5} md={12} lg={12}>
                <Typography
                  variant="body1"
                  color={data.isCombo ? "textSecondary" : ""}
                >{`Stock actual: ${
                  data.isCombo
                    ? type === "mercado libre"
                      ? CalculateStockCombo(articulosSelected)
                      : 0
                    : articulosSelected.length > 0 &&
                        articulosSelected[0].repuesto_sucursal
                      ? articulosSelected[0].repuesto_sucursal.stockDisponible
                      : 0
                }`}</Typography>
              </Grid>
              <Grid
                item
                xs={7}
                sm={7}
                md={12}
                lg={12}
                style={{ paddingTop: 0 }}
              >
                <FormControlLabel
                  style={{ marginBottom: 0 }}
                  control={
                    <Checkbox
                      checked={data.stock}
                      onChange={handleChange}
                      color="primary"
                      name="stock"
                    />
                  }
                  label="SINCRONIZAR STOCK"
                />
              </Grid>
            </>
          )}
          {errorStock && !isEdit && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Alert severity="error">
                {articulosSelected.length > 0 &&
                articulosSelected.some(
                  (articulo) => articulo.repuesto_sucursal === null,
                ) ? (
                  <>
                    <strong>
                      Para sincronizar el stock, el artículo debe ser de la
                      sucursal. Para agregarlo a sucursal presione{" "}
                    </strong>
                    <label
                      onClick={() => addToSucursal()}
                      style={{
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontSize: 15,
                      }}
                    >
                      aquí
                    </label>
                  </>
                ) : (articulosSelected.length > 0 &&
                    articulosSelected.some(
                      (articulo) =>
                        articulo.repuesto_sucursal &&
                        articulo.repuesto_sucursal.stockDisponible <= 0,
                    )) ||
                  CalculateStockCombo(articulosSelected) <= 0 ? (
                  <strong>
                    No se puede sincronizar el stock cuando el mismo es menor o
                    igual a 0
                  </strong>
                ) : publicacion && publicacion.tipo === "free" ? (
                  <strong>
                    No se puede sincronizar el stock porque el tipo de
                    publicación es Free.
                  </strong>
                ) : publicacion &&
                  !publicacion.manejaStock &&
                  type === "woocommerce" ? (
                  <strong>
                    No se puede sincronizar el stock porque esta publicación no
                    maneja stock.
                  </strong>
                ) : (
                  ""
                )}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {data.isCombo && data.stock && (
              <Alert severity="info">
                <strong>
                  Al ser una publicación combo, Mercado Libre tomará siempre el
                  stock del artículo que tenga menor stock para garantizar la
                  disponibilidad de todos los artículos del combo.
                </strong>
              </Alert>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
